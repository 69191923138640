<ng-http-loader>

</ng-http-loader>
<div *ngIf="isLoginRoute; else layout" class="wrapper">
  <router-outlet></router-outlet>
</div>

<ng-template #layout>
  <tg-base-layout>
    <tg-image-holder [height]="30" [routerLink]="['/']" class="pointer"
      imageUrl="assets/images/payinvoice.png"></tg-image-holder>
    <tg-title [level]="1" title="Dashboard"></tg-title>
    <router-outlet></router-outlet>
  </tg-base-layout>
</ng-template>