import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, mergeMap, Observable, of } from 'rxjs';
import { googleAuthCodeFlowConfig } from 'src/app/_config/auth.config.google';
import { microsoftAuthCodeFlowConfig } from 'src/app/_config/auth.config.microsoft';
import { environment } from '../../environments/environment';
import { SSO_CONSTANTS } from '../_constants/sso.constants';
import {
  EmployeeDashboardViewModel,
  JwtViewModel,
  LoginViewModel,
  OAuthCodeViewModel,
  PermissionViewModel, ServerResponseWithBody,
  UserInfoViewModel,
} from '../_models/app.models';
import { IAuthenticationRequest, IInternalSignupStore } from '../_models/cam.models';
import { getUrlPathFragment } from '../_static/util';
import { CamRestService } from './cam-rest.service';
import { CemRestService } from './cem-rest.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSubject = new BehaviorSubject<UserInfoViewModel | null>(null);
  isUatEnv: any;
  constructor(
    private restService: RestService,
    public jwtHelper: JwtHelperService,
    private router: Router,
    private toastrService: ToastrService,
    private oauthService: OAuthService,
    private _camRestService: CamRestService,
    private _cemRestService: CemRestService
  ) {
    const access_token = sessionStorage.getItem('pxp_token');
    if (!!access_token) {
      const valid = !this.jwtHelper.isTokenExpired(access_token);
      if (valid) {
        sessionStorage.setItem('pxp_token', access_token);
        const viewModel =
          this.jwtHelper.decodeToken<UserInfoViewModel>(access_token);

        sessionStorage.setItem('companyId', viewModel.companyId.toString());
        viewModel.roles = this.getRoles(viewModel.permissions);
        viewModel.jwt = access_token;

        const employeeDetails = sessionStorage.getItem('employee_details');

        if (!!employeeDetails) {
          viewModel.employeeDetails = JSON.parse(employeeDetails) as EmployeeDashboardViewModel;
        }

        this.authSubject.next(viewModel);
      } else {
        sessionStorage.removeItem('pxp_token');
      }
    }

    const ssoEnv = localStorage.getItem("OAuth");
    if (ssoEnv === SSO_CONSTANTS.GOOGLE) {
      this.oauthService.configure(googleAuthCodeFlowConfig);
    } else if (ssoEnv === SSO_CONSTANTS.MICROSOFT) {
      this.oauthService.configure(microsoftAuthCodeFlowConfig);
      // Possibly reduntant but kept for safety.
    } else {
      this.oauthService.configure(googleAuthCodeFlowConfig);
    }
  }


  getAuth(): Observable<UserInfoViewModel | null> {
    return this.authSubject.pipe(
      map((v) => {
        if (!this.jwtHelper.isTokenExpired(v?.jwt)) {
          return v;
        }

        return null;
      })
    );
  }

  getLoginStatus(): boolean {
    return !this.jwtHelper.isTokenExpired(this.authSubject.value?.jwt);
  }

  setJwt(jwt: string): boolean {
    const expired = this.jwtHelper.isTokenExpired(jwt);
    if (!expired) {
      sessionStorage.setItem('pxp_token', jwt);
      const viewModel = this.jwtHelper.decodeToken<UserInfoViewModel>(
        jwt
      );

      sessionStorage.setItem('companyId', viewModel.companyId.toString());
      viewModel.roles = this.getRoles(viewModel.permissions);
      viewModel.jwt = jwt;
      this.authSubject.next(viewModel);
    }
    return expired;
  }

  login(form: any, options?: { headers?: HttpHeaders }): Observable<boolean> {
    return this.restService
      .post<LoginViewModel, JwtViewModel>('', form, environment.authUrl, options)
      .pipe(
        map((v) => {
          return this.setJwt(v.jwt);
        }),
        mergeMap(v => {
          return this.getEmployeeDetails(v)
        }),
        mergeMap(v => this.updateUserInfoFromAuth()),
      );
  }

  loginAuthOnly(form: any, options?: { headers?: HttpHeaders }): Observable<boolean> {
    return this.restService
      .post<LoginViewModel, JwtViewModel>('', form, environment.authUrl, options)
      .pipe(
        map((v) => {
          return this.setJwt(v.jwt);
        })
      );
  }

  loginUnEncrypted(request: IInternalSignupStore, options?: { headers?: HttpHeaders }): Observable<boolean> {
    const authRequest: IAuthenticationRequest = {
      username: request.username,
      password: request.password,
      productId: request.productId,
    }
    return this.restService
      .post<IAuthenticationRequest, JwtViewModel>(getUrlPathFragment('auth', 'authenticate'), authRequest, environment.camUrl, options)
      .pipe(
        map((v) => {
          return this.setJwt(v.jwt);
        })
      );
  }

  logout() {
    sessionStorage.removeItem('pxp_token');
    sessionStorage.removeItem('employee_details');
    localStorage.removeItem("OAuth");
    localStorage.removeItem("auth_token_dmr");
    this.authSubject.next(null);
    this.toastrService.warning('Login expired, please login', 'PayExpense');
    this.oauthService.logOut();
    this.router.navigate(['auth/login']);
  }

  isInRole(role: string): Observable<boolean> {
    return this.getAuth().pipe(
      map((v) => {
        if (!!v) {
          return v.roles.indexOf(role) != -1;
        }
        return false;
      })
    );
  }

  getCurrentUserRoles(): Observable<string[]> {
    return this.getAuth().pipe(
      map((v) => {
        if (!!v) {
          return v.roles;
        }

        return [];
      })
    );
  }

  private getRoles(permissions: PermissionViewModel[]): string[] {
    const roles: string[] = [];
    permissions.forEach((p) => {
      const intermediate1 = p.authority.split('Role: ')[1];
      if (!!intermediate1) {
        const role = intermediate1.split(/\(/)[0];
        roles.push(role);
      }
    });

    return roles;
  }

  loginWithAdOauth20(form: OAuthCodeViewModel) {
    return this.restService
      .post<OAuthCodeViewModel, JwtViewModel>('', form, environment.oAuth20Url)
      .pipe(
        map((v) => {
          const expired = this.jwtHelper.isTokenExpired(v.jwt);

          if (!expired) {
            sessionStorage.setItem('pxp_token', v.jwt);
            const viewModel = this.jwtHelper.decodeToken<UserInfoViewModel>(
              v.jwt
            );
            sessionStorage.setItem('companyId', viewModel.companyId.toString());
            viewModel.roles = this.getRoles(viewModel.permissions);
            viewModel.jwt = v.jwt;
            this.authSubject.next(viewModel);
          }

          return expired;
        }),
        mergeMap(v => {
          return this.getEmployeeDetails(v)
        })
      );
  }

  setEmployeeDetails(details: EmployeeDashboardViewModel) {

    sessionStorage.setItem('employee_details', JSON.stringify(details));
  }

  getEmployeeDetails(v: any) {
    if (v) {
      return of(false);
    }
    return this.restService.read<ServerResponseWithBody<EmployeeDashboardViewModel>>(
      getUrlPathFragment('dashboard')
    )
      .pipe(
        map((v) => {
          if (v.body.employeeDetail.status === 'INACTIVE') {
            throw new Error("Invalid User");
          }
          if (v.body.employeeDetail.status === 'TEMPORARY' || v.body.employeeDetail.status === 'PROBATION') {
            const request = {}
            this._cemRestService.put<any, any>(
              getUrlPathFragment('employees/activate-temporary-employee'), request
            ).subscribe((v) => {
              this.toastrService.info(v.body)
            });
          }
          sessionStorage.setItem('employee_details', JSON.stringify(v.body));
          const userInfo = this.authSubject.value;
          if (!!userInfo) {
            userInfo.employeeDetails = v.body;
            this.authSubject.next({...userInfo});
          }
          return true;
        })
      );
  }

  updateUserInfoFromAuth() {
    return this.restService.post<any, any>(getUrlPathFragment('users'), null);
  }
}
